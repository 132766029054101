import { Box, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PeraWalletConnectButton from "./PeraWalletConnect";
import MyAlgoWalletConnectButton from "./MyAlgoConnect";
import { usePeraWallet } from "../context/PeraWallet/Provider";
import { useMyAlgoWallet } from "../context/MyAlgo";
import useAPI from "../context/API/useApi";

const ConnectDialog: React.FC = () => {
  const { isConnectModalShow, setIsConnectModalShow } = useAPI();
  const peraWallet = usePeraWallet();
  const myAlgoWallet = useMyAlgoWallet();

  const handleClose = () => {
    setIsConnectModalShow(false);
  };

  return (
    <Dialog open={isConnectModalShow} onClose={handleClose}>
      <Box
        sx={{
          p: 2,
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <PeraWalletConnectButton
          onClick={() => {
            handleClose();
            peraWallet.connect();
          }}
        />
        <MyAlgoWalletConnectButton
          onClick={() => {
            handleClose();
            myAlgoWallet.connect();
          }}
        />
      </Box>
    </Dialog>
  );
};

export default ConnectDialog;
