import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const rootElement = document.getElementById("alvatars-connect") as HTMLElement;

const root = ReactDOM.createRoot(rootElement);
const algoApiToken = rootElement.getAttribute("algo-token") || undefined;
const algoApiServer = rootElement.getAttribute("algo-server") || undefined;
const algoApiHeaders = rootElement.getAttribute("algo-headers") || undefined;
const showConnectButton = rootElement.hasAttribute("algo-showConnectButton");
root.render(
  <React.StrictMode>
    <App
      token={algoApiToken}
      server={algoApiServer}
      headers={algoApiHeaders}
      showConnectButton={showConnectButton}
    />
  </React.StrictMode>
);
