import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { usePeraWallet } from "../context/PeraWallet/Provider";
import { useMyAlgoWallet } from "../context/MyAlgo";
import useAPI from "../context/API/useApi";
import WalletIcon from "./WalletIcon";
import ConnectDialog from "./ConnectDialog";

const ConnectButton = () => {
  const { isConnected, account, connectedWallet, setConnected } = useAPI();
  const peraWallet = usePeraWallet();
  const myAlgoWallet = useMyAlgoWallet();
  const [isOpen, setisOpen] = useState(false);

  const handleClose = () => {
    setisOpen(false);
  };

  const handleConnect = () => {
    setisOpen(true);
  };

  const handleDisconnect = () => {
    if (connectedWallet === "perawallet") {
      peraWallet.disconnect();
    } else if (connectedWallet === "myalgowallet") {
      myAlgoWallet.disconnect();
    } else {
      console.trace("Unsupported Wallet");
      setConnected(false);
    }
  };

  return (
    <div>
      {isConnected ? (
        <Button onClick={handleDisconnect}>
          <WalletIcon wallet={connectedWallet!} />
          <Typography noWrap sx={{ ml: 2 }}>
            {account}
          </Typography>
        </Button>
      ) : (
        <Button variant="outlined" onClick={handleConnect}>
          Connect
        </Button>
      )}
      <ConnectDialog />
    </div>
  );
};

export default ConnectButton;
