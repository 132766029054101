import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PeraWalletConnect } from "@perawallet/connect";
import useAPI from "../API/useApi";

interface IPeraWalletContext {
  instance: PeraWalletConnect;
  connect: () => void;
  disconnect: () => void;
}

const PeraWalletContext = createContext({} as IPeraWalletContext);

export const usePeraWallet = () => useContext(PeraWalletContext);

const PeraWalletProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { setConnected, perawalletInstance: instance } = useAPI();
  const [isMounted, setIsMounted] = useState(false);

  const disconnect = useCallback(() => {
    if (!instance.connector?.connected) {
      return;
    }
    instance.disconnect();
    setConnected(false);
  }, [instance, setConnected]);

  const handleConnectWallet = useCallback(
    (account: string) => {
      instance.connector?.on("disconnect", disconnect);
      setConnected(true, account, "perawallet");
    },
    [disconnect, instance, setConnected]
  );

  const connect = useCallback(() => {
    instance
      .connect()
      .then((accounts) => handleConnectWallet(accounts[0]))
      .catch((error) => {
        if (error?.data?.type !== "CONNECT_MODAL_CLOSED") {
        }
      });
  }, [handleConnectWallet, instance]);

  useEffect(() => {
    if (isMounted) {
      return;
    }
    setIsMounted(true);
  }, [isMounted]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }
    instance
      .reconnectSession()
      .then((accounts) => {
        instance.connector?.on("disconnect", disconnect);

        if (accounts.length) {
          handleConnectWallet(accounts[0]);
        }
      })
      .catch(() => {});
  }, [disconnect, handleConnectWallet, instance, isMounted]);

  return (
    <PeraWalletContext.Provider
      value={{
        instance,
        connect,
        disconnect,
      }}
    >
      {children}
    </PeraWalletContext.Provider>
  );
};

export default PeraWalletProvider;
