import { createContext } from "react";
import MyAlgoConnect from "@randlabs/myalgo-connect";

interface IMyAlgoContext {
  instance: MyAlgoConnect;
  connect: () => void;
  disconnect: () => void;
}

export const MyAlgoContext = createContext({} as IMyAlgoContext);

export default MyAlgoContext;
