import { Button, Typography } from "@mui/material";
import WalletIcon from "./WalletIcon";

const MyAlgoWalletConnectButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{ display: "flex", mb: 2 }}
    >
      <WalletIcon wallet="myalgowallet" />
      <Typography sx={{ ml: 2 }}>MyAlgo Wallet</Typography>
    </Button>
  );
};

export default MyAlgoWalletConnectButton;
