import { PeraWalletConnect } from "@perawallet/connect";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { createContext } from "react";

export type SupportedWallet = "perawallet" | "myalgowallet";

interface IAPIContext {
  connectedWallet?: SupportedWallet;
  isConnected: boolean;
  account?: string;
  setConnected: (
    isConnected: boolean,
    account?: string,
    connectedWallet?: SupportedWallet
  ) => void;
  isConnectModalShow: boolean;
  setIsConnectModalShow: (show: boolean) => void;
  perawalletInstance: PeraWalletConnect;
  myalgoInstance: MyAlgoConnect;
}

const APIContext = createContext({} as IAPIContext);

export default APIContext;
