import ConnectButton from "./components/ConnectButton";
import ConnectDialog from "./components/ConnectDialog";

import APIProvider, { AlgoApiProps } from "./context/API/Provider";
import MyAlgoProvider from "./context/MyAlgo/Provider";
import PeraWalletProvider from "./context/PeraWallet/Provider";

type AppProps = AlgoApiProps & {
  showConnectButton: boolean;
};

const App: React.FC<Partial<AppProps>> = (props) => {
  return (
    <APIProvider {...props}>
      <PeraWalletProvider>
        <MyAlgoProvider>       
          {props.showConnectButton && <ConnectButton />}
          <ConnectDialog />  
        </MyAlgoProvider>    
      </PeraWalletProvider>        
    </APIProvider>
  );
};

export default App;
