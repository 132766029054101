import { ReactNode } from "react";
import MyAlgoContext from "./context";
import useAPI from "../API/useApi";

export const MyAlgoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { setConnected, myalgoInstance: instance } = useAPI();

  const connect = () => {
    instance.connect({ shouldSelectOneAccount: true }).then((accounts) => {
      const connectedAccountAddress = accounts[0].address;
      setConnected(true, connectedAccountAddress, "myalgowallet");
    });
  };

  const disconnect = () => {
    setConnected(false);
  };

  return (
    <MyAlgoContext.Provider
      value={{
        instance,
        connect,
        disconnect,
      }}
    >
      {children}
    </MyAlgoContext.Provider>
  );
};

export default MyAlgoProvider;
