import { Button, Typography } from "@mui/material";
import WalletIcon from "./WalletIcon";

const PeraWalletConnectButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{ display: "flex", mb: 2, justifyContent: "left", width: "100%" }}
    >
      <WalletIcon wallet="perawallet" />
      <Typography sx={{ ml: 2 }}>Pera Wallet</Typography>
    </Button>
  );
};

export default PeraWalletConnectButton;
