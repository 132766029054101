import PeraWalletIcon from "./icons/perawallet.svg";
import MyAlgoWalletIcon from "./icons/myalgowallet.svg";
import { SupportedWallet } from "../context/API/context";

const WalletIcon: React.FC<{ wallet: SupportedWallet }> = ({ wallet }) => {
  if (wallet === "perawallet") {
    return <img src={PeraWalletIcon} alt="Pera Wallet Icon" />;
  }
  if (wallet === "myalgowallet") {
    return <img src={MyAlgoWalletIcon} alt="MyAlgo Wallet Icon" />;
  }
  return null;
};

export default WalletIcon;
