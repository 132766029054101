import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import APIContext, { SupportedWallet } from "./context";
import algosdk from "algosdk";
import { NFTStorage, Blob } from 'nft.storage'
import * as digest from 'multiformats/hashes/digest'
import * as mfsha2 from 'multiformats/hashes/sha2'
import { CIDVersion } from 'multiformats/types/src/cid'
import { CID } from 'multiformats/cid'
import { PeraWalletConnect } from "@perawallet/connect";
import MyAlgoConnect from "@randlabs/myalgo-connect";

const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDdBRTViOTRlYzlBN2RFOTBCMTg4MmE1NDg2ZDIxMDE1NjhjNWYwNjYiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY4Mjk5MzQyNjExOCwibmFtZSI6IkFsdmF0YXJzIFByb2ZpbGUgTkZUIn0.0yBqU8PwClsoLDxwIp-dveHGw7bSt3HBKNcsrAFQMVU'

const TransactionKeyPhrase = "NIPPY CALLISTO COVI BIBI";

const AlgoCoinAssetId = 553615859;
const CardsManagerAddress = "ALVA7QT5JWKXMWGNYL3JYFTCFFCYJVUIFZAD4S7AKFW5M7OI6Q7X3EAGFY";
const ProfileManagerAddress = "FAME7SSFDCFCZ335DIS7UAKSC6IP4GGJ66F6OPURMA4JXUCNEMOLGWIEKY";
const SalesManagerAddress = "PACKHZVVUHKEWNR5W3JNZPKSQOKUUXN7DO7P5BT6KCLOTYY5ZN5NBF764U";

const ProfileManagerMnemonic = "approve trade please today excess drop tissue expand hundred loan wheat liberty move evoke sponsor quote hour unveil wagon gesture lonely forum transfer able peasant";

interface AlvatarsApi {
  onConnect: (account: string) => void;
  onDisconnect: () => void;
  getBalance: (account: string) => Promise<{
    raw: number;
    formatted: string;
  }>;
  getAlvaCoinBalance: (account: string) => Promise<{
    raw: number;
    formatted: string;
  }>;
  getAlvatarAssets: (account: string) => Promise<any>;  
  getAlvatarProfileAssets: (account: string) => Promise<any>;
  showConnectModal: () => void;
  upgradeAsset: (key : string, address: string, assetIndex: number, metadata: string, amount: bigint) => Promise<any>;
}

declare global {
  interface Window {
    alvatarsApi?: Partial<AlvatarsApi>;
  }
}

export type AlgoApiProps = {
  token?: string;
  server: string;
  headers?: string;
};

interface ApiProps {
  algoApi?: AlgoApiProps;
  children: React.ReactNode;
}

const defaultAlgoApiProps = {
  token: "5067UME556XS1SR22YV8KPBA4YSVCWIC",
  server: "https://mainnet-api.algonode.cloud/",
  headers: "",
};

const APIProvider: React.FC<ApiProps> = ({ children, algoApi }) => {  
  const myalgoInstance = useMemo(() => new MyAlgoConnect(), []);
  const perawalletInstance = useMemo(
    () =>
      new PeraWalletConnect({
        shouldShowSignTxnToast: false,
      }),
    []
  );

  const algodClient = useMemo(
    () =>
      new algosdk.Algodv2(
        algoApi?.token ?? defaultAlgoApiProps.token,
        algoApi?.server ?? defaultAlgoApiProps.server,
        algoApi?.headers ?? defaultAlgoApiProps.headers
      ),
    [algoApi]
  );

  const indexerClient = useMemo(
    () =>
      new algosdk.Indexer("5067UME556XS1SR22YV8KPBA4YSVCWIC", "https://mainnet-idx.algonode.cloud/", 443),
    []
  );

  const onConnectCallback = useRef<(account: string) => void>();
  const [onConnectCallbackCalls, setConnectCallbackCalls] = useState<string[]>(
    []
  );
  const onDisconnectCallback = useRef<() => void>();

  const [isConnected, setIsConnected] = useState(false);
  const [account, setAccount] = useState<string>();
  const [connectedWallet, setConnectedWallet] = useState<SupportedWallet>();
  const [isConnectModalShow, setIsConnectModalShow] = useState(false);

  const setConnected = useCallback(
    (
      isConnected: boolean,
      account?: string,
      connectedWallet?: SupportedWallet
    ) => {
      if (isConnected && account) {
        if (onConnectCallback.current) {
          onConnectCallback.current(account);
        } else {
          setConnectCallbackCalls((prev) => [...prev, account]);
        }
      }
      if (!isConnected && onDisconnectCallback.current) {
        onDisconnectCallback.current();
      }
      setIsConnected(isConnected);
      setAccount(account);
      setConnectedWallet(connectedWallet);
    },
    []
  );

  const setupApi = useCallback(() => {
    /*if (!window.alvatarsApi) {
      return;
    }*/
    window.alvatarsApi = window.alvatarsApi??{};
    onConnectCallback.current =
      window.alvatarsApi?.onConnect ?? onConnectCallback.current;
    if (onConnectCallback.current && onConnectCallbackCalls.length > 0) {
      onConnectCallbackCalls.forEach((callArgs) => {
        onConnectCallback.current!(callArgs);
      });
      setConnectCallbackCalls([]);
    }
    onDisconnectCallback.current =
      window.alvatarsApi?.onDisconnect ?? onDisconnectCallback.current;

    window.alvatarsApi.getBalance = async (address) => {
      const account = await algodClient.accountInformation(address).do();

      return {
        raw: account.amount,
        formatted: (parseFloat(account.amount) / Math.pow(10, 6)).toFixed(5),
      };
    };
    window.alvatarsApi.getAlvaCoinBalance = async (address) => {
      const account = await algodClient.accountInformation(address).do();
      const algoAsset = account.assets.find(
        (asset: { [x: string]: number }) =>
          asset["asset-id"] === AlgoCoinAssetId
      );
      const rawAmount = algoAsset?.amount ?? 0;
      return {
        raw: rawAmount,
        formatted: (parseFloat(rawAmount) / Math.pow(10, 2)).toFixed(2),
      };
    };

    window.alvatarsApi.getAlvatarAssets = async (address) => {
      const account = await algodClient.accountInformation(address).do();
      const assets = account.assets.map((asset: { [x: string]: number }) =>
        indexerClient.lookupAssetByID(asset["asset-id"]).includeAll(true).do()
      );
      if (assets.length === 0) {
        return [];
      }

      let alvatarAssets = await Promise.all(assets).then((assets) =>
        assets.filter(
          ({ asset }) => asset.params.manager === CardsManagerAddress
        )
      );

      if (alvatarAssets.length === 0) {
        return [];
      }

      alvatarAssets = await Promise.all(
        alvatarAssets.map((alvatarAsset) => {
          return indexerClient
            .lookupAssetBalances(alvatarAsset.asset.index)
            .do()
            .then(({ balances }) => {
              if (!Array.isArray(balances)) {
                return null;
              }
              const entry = balances.find((entry) => entry.address === address);
              if (!entry) {
                return null;
              }
              return entry.amount > 0 ? alvatarAsset : null;
            });
        })
      );
      alvatarAssets = alvatarAssets.filter(Boolean);

      if (alvatarAssets.length === 0) {
        return [];
      }

      const metadata = await Promise.all(
        alvatarAssets.map(({ asset }) => {
          return fetch(
            `https://api.algoxnft.com/v1/assets/${asset.index}/arc69`
          ).then(async (resp) => {
            const data = await resp.json();
            return [asset.index, data];
          });
        })
      );
      const metadataLookup = metadata.reduce((acc, item) => {
        acc.set(item[0], item[1]);
        return acc;
      }, new Map());

      return alvatarAssets.map(({ asset }) => ({
        asset,
        metadata: metadataLookup.get(asset.index),
      }));
    };

    
    window.alvatarsApi.getAlvatarProfileAssets = async (address) => {

      const account = await algodClient.accountInformation(address).do();
      const assets = account.assets.map((asset: { [x: string]: number }) =>
        indexerClient.lookupAssetByID(asset["asset-id"]).includeAll(true).do()
      );
      if (assets.length === 0) {
        return [];
      }

      let alvatarAssets = await Promise.all(assets).then((assets) =>
        assets.filter(
          ({ asset }) => asset.params.manager === ProfileManagerAddress
        )
      );

      if (alvatarAssets.length === 0) {
        return [];
      }

      alvatarAssets = await Promise.all(
        alvatarAssets.map((alvatarAsset) => {
          return indexerClient
            .lookupAssetBalances(alvatarAsset.asset.index)
            .do()
            .then(({ balances }) => {
              if (!Array.isArray(balances)) {
                return null;
              }
              const entry = balances.find((entry) => entry.address === address);
              if (!entry) {
                return null;
              }
              return entry.amount > 0 ? alvatarAsset : null;
            });
        })
      );
      alvatarAssets = alvatarAssets.filter(Boolean);

      if (alvatarAssets.length === 0) {
        return [];
      }

      const metadata = await Promise.all(
        alvatarAssets.map(({ asset }) => {
          const addr = algosdk.decodeAddress(asset.params.reserve)
          const mhdigest = digest.create(mfsha2.sha256.code, addr.publicKey)
          const cid = CID.create(1 as CIDVersion, 0x55, mhdigest)

          return fetch(
            `https://cloudflare-ipfs.com/ipfs/${cid.toString()}#arc3`
          ).then(async (resp) => {
            const data = await resp.json();
            return [asset.index, data];
          });
        })
      );
      const metadataLookup = metadata.reduce((acc, item) => {
        acc.set(item[0], item[1]);
        return acc;
      }, new Map());

      return alvatarAssets.map(({ asset }) => ({
        asset,
        metadata: metadataLookup.get(asset.index),
      }));
    };

    window.alvatarsApi.showConnectModal = () => {
      setIsConnectModalShow(true);
    };

    window.alvatarsApi.upgradeAsset = async (key, address, assetIndex, metadata, amount) => 
    {
      if(key !== TransactionKeyPhrase){ return "Keyphrase Incorrect"; }
      
      const suggestedParams = await algodClient.getTransactionParams().do();
      const paymentTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        from: address,
        to: SalesManagerAddress,
        amount: amount,
        suggestedParams,
      });

      const client = new NFTStorage({ token: NFT_STORAGE_TOKEN })
      const meta = new Blob([metadata])
      var ReserveAddress = ""

      try {
        //Reserve Address
        const storedMeta = await client.storeBlob(meta);
        const CID = require('cids')
        const cid = new CID(storedMeta)      
        ReserveAddress = algosdk.encodeAddress(cid.multihash.slice(2))
      } catch (error) {
        return [{'Upload Error' : error}];
      }
        
      const creator = algosdk.mnemonicToSecretKey(ProfileManagerMnemonic);

      const assetConfigTxn = await algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject(
      {
        from: ProfileManagerAddress,
        suggestedParams,
        assetIndex: assetIndex,
        manager: ProfileManagerAddress,
        reserve: ReserveAddress,
        strictEmptyAddressChecking: false
      });  

      algosdk.assignGroupID([paymentTxn, assetConfigTxn]);

      try {
        if(connectedWallet === "perawallet"){
          const signedAssetConfig = assetConfigTxn.signTxn(creator.sk);
          const paymentTxnGroup = [{txn: paymentTxn, signers: [address]}, {txn: assetConfigTxn, signers: []}];        
          const signedPaymentTxn = await perawalletInstance.signTransaction([paymentTxnGroup]);
          signedPaymentTxn[1] = signedAssetConfig;

          const {txId} = await algodClient.sendRawTransaction(signedPaymentTxn).do();

          await algosdk.waitForConfirmation(algodClient, txId, 3);
          return [{'Transaction Success' : txId}];
        } 
        else{
          const signedPaymentTxn = await myalgoInstance.signTransaction(paymentTxn.toByte());                
          const signedMintTxn = assetConfigTxn.signTxn(creator.sk);
          const signedTxns = [signedMintTxn, signedPaymentTxn.blob];

          const {txId} = await algodClient.sendRawTransaction(signedTxns).do();
          await algosdk.waitForConfirmation(algodClient, txId, 3);
          return [{'Transaction Success' : txId}];
        }
      } catch (error) {
        return [{'Transaction Failed' : error}];
      }
    };
  }, [algodClient, onConnectCallbackCalls, setConnectCallbackCalls, connectedWallet]);

  useEffect(() => {
    setTimeout(() => setupApi(), 100);
  }, [setupApi]);

  return (
    <APIContext.Provider
      value={{
        isConnected,
        account,
        setConnected,
        connectedWallet,
        isConnectModalShow,
        setIsConnectModalShow,
        perawalletInstance,
        myalgoInstance,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export default APIProvider;
